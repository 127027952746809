import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewportEnter from 'components/viewport-enter';
import Image from 'components/image';
import { Container, Row } from 'components/layout';

import s from './Signals.scss';

export default class Signals extends Component {

  state = {
    counter: 0,
  }

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    count: PropTypes.number,
    duration: PropTypes.number,
    image: PropTypes.object,
  }

  static defaultProps = {
    duration: 3000,
  }

  shouldComponentUpdate(nextProps, nextState) {
    // lint bug
    // eslint-disable-next-line react/prop-types
    if (this.state.counter !== nextState.counter) {
      return true;
    }
    return false;
  }

  easing = (value) => {

    let ease;
    let n = value;

    if (n < 0.5) {
      ease = 16 * n * n * n * n * n;
    } else {
      ease = 1 + (16 * (n -= 1) * n * n * n * n);
    }

    return ease;
  }

  // start counter
  onEnter = () => {
    const { count, duration } = this.props;
    let start;

    const step = (now) => {
      if (!start) {
        start = now;
      }

      // elapsed time
      const progress = (now - start) / duration;

      const value = progress * count;
      const easedValue = Math.floor(value * this.easing(progress));

      if (easedValue < count) {
        this.setState({
          counter: easedValue,
        });
      } else {
        this.setState({
          counter: count,
        });
      }

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    // begin
    requestAnimationFrame(step);
  }

  // regex add comma to number
  formatNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  render() {
    const { heading, text, image } = this.props;

    const { counter } = this.state;

    const IMAGE_WIDTH = 1545;

    return (
      <div className={s('signals', { active: counter > 0 })}>
        <Container>
          <Row>
            <div className={s.signals__col}>
              <h1 className={s.signals__heading}>{heading}</h1>
              <ViewportEnter onEnter={this.onEnter}>
                <div className={s.signals__count}>{this.formatNumber(counter)}</div>
              </ViewportEnter>
              <p className={s.signals__text}>{text}</p>
            </div>
            {image && image.src && (
              <div className={s.signals__col}>
                <div className={s.signals__imageWrap}>
                  <div className={s.signals__image}>
                    <Image
                      src={`${image.src}?w=${IMAGE_WIDTH}`}
                      src2x={`${image.src}?w=${IMAGE_WIDTH * 2}`}
                      width={image.width}
                      height={image.height}
                      alt={image.alt || ''}
                    />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>

      </div>
    );
  }
}
