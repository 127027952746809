import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Segment from 'components/layout';

import NumberedListItem from './NumberedListItem';

import s from './NumberedList.scss';

export default class NumberedList extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    items: PropTypes.array,
  }

  static defaultProps = {
    heading: null,
    items: null,
  }

  render() {

    const {
      heading,
      items,
    } = this.props;

    return (
      <div className={s.numberedList}>
        <Segment container>
          <h2 className={s.numberedList__heading}>{heading}</h2>
          <ol className={s.numberedList__row}>
            {items && items.map((item, i) => (
              <li
                key={_get(item, 'heading')}
                className={s.numberedList__item}
              >
                <NumberedListItem index={i} heading={_get(item, 'heading')} text={_get(item, 'text')} />
              </li>
            ))}
          </ol>
        </Segment>
      </div>
    );
  }
}
