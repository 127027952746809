import React from 'react';
import PropTypes from 'prop-types';

import s from './NumberedListItem.scss';

const NumberedListItem = ({ index, heading, text }) => (
  <div className={s.numberedListItem}>
    <div className={s.numberedListItem__number}>0{index + 1}</div>
    <div className={s.NumberedListItem__content}>
      <h3 className={s.numberedListItem__heading}>{heading}</h3>
      <p className={s.mumberedListItem__text}>{text}</p>
    </div>
  </div>
);

NumberedListItem.propTypes = {
  index: PropTypes.number,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default NumberedListItem;
