import { graphql } from 'gatsby';
import About from 'routes/about/About';

export default About;

export const query = graphql`
  query About {
    page: contentfulPageAbout {
      pageTitle
      metaTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroImage {
        ...image
      }
      modules {
        ...moduleList
      }
      principlesHeading: principlesTitle
      principles {
        ...submoduleList
      }
      signals {
        ...moduleList
      }
      signalsCount
      signalsFinePrint
      customersHeading: customersTitle
      customersText: customersBody {
        text: customersBody
      }
      customersList {
        ...moduleList
      }
      offices {
        ...moduleList
      }
      contactHeading: contactTitle
      contactEmail
      contactPhone
      contactCtaText: contactChatText
    }
  }
`;
