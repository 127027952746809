import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import LazyLoad from 'react-lazyload'

import extractMeta from 'utils/extract-meta'
import Modules from 'containers/modules'
import HeroIntro from 'components/hero-intro'
import SEO from 'components/seo'
import OfficesMap from 'components/offices-map'

import Contact from './components/contact'
import NumberedList from './components/numbered-list'
import AboutTestimonials from './components/testimonials'
import Signals from './components/signals'
import Offices from './components/offices'

export default class About extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props.data
    const { pathname } = this.props.location

    return (
      <Fragment>
        <SEO pathname={pathname} article {...extractMeta(page)} />

        <HeroIntro
          heading={page.heroHeading}
          image={{
            src: _get(page.heroImage, 'file.url'),
            alt: _get(page.heroImage, 'description'),
            width: _get(page.heroImage, 'file.details.image.width'),
            height: _get(page.heroImage, 'file.details.image.height'),
          }}
          text2={_get(page.heroText, 'text')}
        />

        <LazyLoad once offset={500}>
          <Modules list={page.modules} />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <NumberedList
            heading={page.principlesHeading}
            items={page.principles.map(principle => ({
              heading: principle.heading,
              text: principle.text.text,
            }))}
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Signals
            data={page.signals}
            heading={page.signals.heading}
            count={parseInt(page.signalsCount, 10)}
            text={page.signalsFinePrint}
            image={{
              src: _get(page.signals.image, 'file.url'),
              alt: _get(page.signals.image, 'description'),
              width: _get(page.signals.image, 'file.details.image.width'),
              height: _get(page.signals.image, 'file.details.image.height'),
            }}
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <AboutTestimonials
            heading={page.customersHeading}
            text={_get(page.customersText, 'text')}
            testimonials={page.customersList.map(customer => ({
              heading: _get(customer.quote, 'text'),
              text: customer.authorName,
              image: {
                src: _get(customer.authorPortrait, 'file.url'),
                alt: _get(customer.authorPortrait, 'description'),
                width: _get(
                  customer.authorPortrait,
                  'file.details.image.width'
                ),
                height: _get(
                  customer.authorPortrait,
                  'file.details.image.height'
                ),
              },
            }))}
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Offices
            heading={page.offices.heading}
            offices={page.offices.iconBlocks}
          >
            <OfficesMap image={require('assets/images/offices-map.png')} />
          </Offices>
        </LazyLoad>

        <Contact
          heading={page.contactHeading}
          email={page.contactEmail}
          phone={page.contactPhone}
          ctaText={page.contactCtaText}
        />
      </Fragment>
    )
  }
}
