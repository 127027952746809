/* global Intercom */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Segment from 'components/layout';
import Button from 'components/button';

import s from './Contact.scss';

export default class Contact extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    ctaText: PropTypes.string,
  }

  static defaultProps = {
    heading: undefined,
    email: null,
    phone: null,
    ctaText: 'Chat Now',
  }

  handleContactButtonClick = () => {
    Intercom('show');
  }

  render() {
    const { heading, email, phone, ctaText } = this.props;

    return (
      <Segment container>
        <div className={s.contact}>
          <div>
            <h2 className={s.contact__heading}>{heading}</h2>

            <ul className={s.contact__links}>
              <li className={s.contact__item}>
                <a href={`mailto:${email}`}>
                  {email}
                </a>
              </li>
              <li className={s.contact__item}>
                <a href={`tel:${phone}`}>
                  {/* TODO: strip visual seperators in phone for use in href */}
                  {phone}
                </a>
              </li>
            </ul>
          </div>

          <div className={s.contact__button}>
            {typeof Intercom !== 'undefined' && (
              <Button color="red" onClick={this.handleContactButtonClick}>
                {/* TODO: link to chat service, likely not a URL */}
                {ctaText}
              </Button>
            )}
          </div>
        </div>
      </Segment>
    );
  }
}
