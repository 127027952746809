import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './AboutTestimonialsItem.scss';

const AboutTestimonialsItem = ({ quote, cite, image }) => (
  <div className={s.aboutTestimonialsItem}>
    <div className={s.aboutTestimonialsItem__content}>
      <q className={s.aboutTestimonialsItem__quote}>{quote}</q>
      <span className={s.aboutTestimonialsItem__cite}>{cite}</span>
    </div>
    {image && image.src && (
      <div className={s.aboutTestimonialsItem__image}>
        <Image
          src={`${image.src}?w=${450}`}
          src2x={`${image.src}?w=${450 * 2}`}
          width={image.width}
          height={image.height}
        />
      </div>
    )}
  </div>
);

AboutTestimonialsItem.propTypes = {
  quote: PropTypes.string,
  cite: PropTypes.string,
  image: PropTypes.object,
};

export default AboutTestimonialsItem;
