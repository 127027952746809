import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/carousel';
import Leader from 'components/leader';

import s from './AboutTestimonials.scss';
import AboutTestimonialsItem from './AboutTestimonialsItem';

export default class AboutTestimonials extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      }),
    ),
  }

  static defaultProps = {
    heading: null,
    text: null,
    testimonials: [],
  }

  render() {
    const { heading, text, testimonials } = this.props;

    return (
      <div className={s.testimonials}>
        <Leader heading={heading} text={text} className={s.testimonials__leader} />
        <Carousel narrow>
          {testimonials.map(testimonial => (
            <AboutTestimonialsItem
              key={testimonial.text}
              quote={testimonial.heading}
              cite={testimonial.text}
              image={testimonial.image}
            />
          ))}
        </Carousel>
      </div>
    );
  }
}
