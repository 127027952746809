import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import { Container, Row } from 'components/layout';
import IconBlock from 'components/icon-block';

import s from './Offices.scss';

export default class Offices extends Component {

  static propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node,
    offices: PropTypes.array,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    const { heading, children, offices } = this.props;

    return (
      <div className={s.offices}>
        <Container>
          <h1 className={s.offices__heading}>{heading}</h1>
          {children}

          <Row>
            {offices.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={s.offices__item} key={`${s.offices__item}-${i}`}>
                <IconBlock
                  heading={item.heading}
                  text={item.text.text}
                  cta={item.ctaUrl && <Clickable to={item.ctaUrl}>{item.ctaText}</Clickable>}
                />
              </div>
            ))}

          </Row>
        </Container>
        <hr className={s.offices__hr} />
      </div>
    );
  }
}
